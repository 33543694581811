import React from 'react'
import { FuneralInfo, ImageAndMessage, MessageOnly, QuoteAndMessage } from '../Templates'

const Step2 = props => {
  const { getStore } = props
  const goToNextStep = e => {
    e.preventDefault()
    props.updateStore({
      tributeType: e.target.getAttribute('data-type'),
    })
    props.jumpToStep(2)
  }

  return (
    <section className="box tc ph2 w-100 pa3 pa4-ns mb3">
      <div className="mw8-ns center mt3-ns">
        <form>
          <h2 className="ma0 mb0 mb4-ns fw5 f4 tc w-100 lh-title ph3 f5 f4-ns">
            Selecione o tipo de tributo que deseja publicar
          </h2>
          <div className="flex flex-wrap justify-center justify-start-l">
            <div className="pa3 w-100 w-50-ns flex flex-wrap flex-column">
              <MessageOnly />
              <button
                type="button"
                data-type="MessageOnly"
                onClick={e => goToNextStep(e)}
                className="btn btn-primary w-100 mt2 lh-title"
              >
                Somente Mensagem
              </button>
            </div>
            <div className="pa3 w-100 w-50-ns flex flex-wrap flex-column">
              <ImageAndMessage />
              <button
                type="button"
                data-type="ImageAndMessage"
                onClick={e => goToNextStep(e)}
                className="btn btn-primary w-100 mt2 lh-title"
              >
                Imagem com Mensagem
              </button>
            </div>
            <div className="pa3 w-100 w-50-ns flex flex-wrap flex-column">
              <QuoteAndMessage quoteBackgroundImage={getStore().defaultQuoteBackgroundImage} />
              <button
                type="button"
                data-type="QuoteAndMessage"
                onClick={e => goToNextStep(e)}
                className="btn btn-primary w-100 mt2 lh-title"
              >
                Citação com Mensagem
              </button>
            </div>
            <div className="pa3 w-100 w-50-ns flex flex-wrap flex-column">
              <FuneralInfo
                venueName="Cemitério da Paz"
                venueAddress="Rua Dr. Luiz Migliano, 644 - Jardim Vazani, São Paulo"
                venueDateTime={new Date(new Date().getFullYear(), 11, 31)}
              />
              <button
                type="button"
                data-type="FuneralInfo"
                onClick={e => goToNextStep(e)}
                className="btn btn-primary w-100 mt2 lh-title"
              >
                Informações sobre Velório
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}

export default Step2
