import React, { Component } from 'react'
import StepZilla from 'react-stepzilla'

import SEO from '../components/seo'
import { withFirebase } from '../components/firebase/context'
import Step1 from '../components/Tributes/Step1'
import Step2 from '../components/Tributes/Step2'
import Step3 from '../components/Tributes/Step3'
import { getStringParams } from '../components/helpers/UIHelpers'

class CreateObituary extends Component {
  initFirebase = false

  constructor(props) {
    super(props)
    const companyId =
      typeof window !== 'undefined' ? getStringParams(window.location.href).companyId : null
    this.state = {}

    this.sampleStore = {
      companyId,
      city: '',
      personName: '',
      userName: '',
      userEmail: '',
      savedToCloud: false,
    }
  }

  getStore() {
    return this.sampleStore
  }

  updateStore(update) {
    this.sampleStore = {
      ...this.sampleStore,
      ...update,
    }
  }

  render() {
    const steps = [
      {
        name: 'Homenageado',
        component: (
          <Step1
            getStore={() => this.getStore()}
            updateStore={u => {
              this.updateStore(u)
            }}
          />
        ),
      },
      {
        name: 'Tipo de Nota',
        component: (
          <Step2
            getStore={() => this.getStore()}
            updateStore={u => {
              this.updateStore(u)
            }}
          />
        ),
      },
      {
        name: 'Conteúdo',
        component: (
          <Step3
            getStore={() => this.getStore()}
            updateStore={u => {
              this.updateStore(u)
            }}
          />
        ),
      },
    ]
    return (
      <>
        <SEO title="Criação de Tributo" />
        <section className="tc ph2 w-100 mb2 mb3-m mb4-l">
          <div className="mw8-ns center ph2">
            <h1 className="f5 f4-m f3-l mv4">Criação de Tributo</h1>
            <div className="step-progress mt4">
              <StepZilla
                steps={steps}
                stepsNavigation={false}
                preventEnterSubmission
                dontValidate={false}
                onStepChange={step => window.sessionStorage.setItem('step', step)}
                startAtStep={0}
              />
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default withFirebase(CreateObituary)
