import React, { Component } from 'react'
import { confirmAlert } from 'react-confirm-alert'

import City from '../City/City'
// import getFirebase from '../firebase/firebase'
import { withFirebase } from '../firebase/context'
import FormInlineError from '../helpers/FormInlineError'
import { toTitleCase, isEmail } from '../helpers/UIHelpers'
import { AuthUserContext } from '../Session'
// import { withFirebase } from '../firebase/context'

class Step1 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firebaseLoaded: false,
      personCity: props.getStore().personCity,
      personName: props.getStore().personName,
      userName: props.getStore().userName,
      userEmail: props.getStore().userEmail,
      hasSubmittedOnce: false,
      isCompany: false,
    }
    const { updateStore } = this.props
    this.validateOnDemand = true
    this.validationCheck = this.validationCheck.bind(this)
    this.isValidated = this.isValidated.bind(this)
    updateStore({
      defaultQuoteBackgroundImage:
        'https://firebasestorage.googleapis.com/v0/b/immortalis.appspot.com/o/site%2Ftexto-imagem.jpg?alt=media&token=063ba1a8-fb35-48f0-9740-02bea88a9f67',
    })
  }

  componentDidUpdate() {
    const { firebase, getStore } = this.props
    const { firebaseLoaded } = this.state
    if (getStore().companyId) {
      if (firebase && !firebaseLoaded) {
        this.getCompanyData()
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ firebaseLoaded: true })
      }
    }
  }

  getCompanyData = () => {
    const { firebase, getStore } = this.props
    const { companyId } = getStore()
    const { firestore } = firebase
    firestore
      .collection('Companies')
      .doc(companyId)
      .get()
      .then(companyDoc => {
        const userData = JSON.parse(window.sessionStorage.getItem('immortalis-user'))
        if (userData && userData.companies.includes(companyId)) {
          this.setState({
            companyLogo: companyDoc.data().logo,
            companyName: companyDoc.data().name,
            isCompany: true,
          })
        }
      })
  }

  handleCity = cityValue => {
    const { updateStore } = this.props
    this.validationCheck()
    updateStore({
      personCity: cityValue,
    })
    this.setState({
      personCity: cityValue,
    })
  }

  goToNextStep = e => {
    const { jumpToStep } = this.props
    e.preventDefault()
    this.setState({
      hasSubmittedOnce: true,
    })
    this.isValidated().then(isValid => {
      if (isValid) {
        jumpToStep(1)
      }
    })
  }

  validationErrors = val => {
    const errMsgs = {
      personNameValMsg: val.personNameVal
        ? ''
        : 'Digite os primeiro e último nomes da pessoa homenageada.',
      personCityValMsg:
        val.personCityVal === '' ? '' : 'Digite a cidade e selecione na lista a opção desejada.',
      userNameValMsg: val.userName
        ? ''
        : 'Digite o nome que será apresentado como publicador da nota.',
      userEmailValMsg: val.userEmail ? '' : 'Insira um email válido',
    }
    return errMsgs
  }

  validateData = data => ({
    personNameVal: data.personName !== '' && data.personName.indexOf(' ') !== -1,
    userNameVal: !data.userName || data.userName !== '',
    personCityVal: data.personCity !== '' && data.personCity !== undefined,
    userEmailVal: !data.userEmail || isEmail(data.userEmail),
  })

  isExistingUser = () =>
    new Promise(resolve => {
      const { firebase, getStore } = this.props
      const { auth } = firebase
      const isLoggedIn = !!auth.currentUser
      if (isLoggedIn) {
        resolve(false)
      } else {
        auth.fetchSignInMethodsForEmail(getStore().userEmail).then(activeSignInMethods => {
          if (activeSignInMethods.length) {
            confirmAlert({
              customUI: ({ onClose }) => (
                <div className="fixed top-0 bg-black-30 left-0 w-100 h-100 flex items-center justify-center sans-serif">
                  <div className="box pa3 w-90 w-50-m w-40-l tc">
                    <p className="mb4 lh-title">
                      Um usuário com este e-mail já está cadastrado no Immortalis.
                    </p>
                    <div className="flex w-100 flex-wrap justify-center">
                      <button
                        type="button"
                        className="mh2 w-100 w-auto-ns mr2-ns btn btn-primary mb2 mb0-ns"
                        onClick={() => {
                          window.location.href = '/signin'
                        }}
                      >
                        Acessar Conta
                      </button>
                    </div>
                  </div>
                </div>
              ),
            })
          } else {
            resolve(false)
          }
        })
      }
    })

  validationCheck() {
    if (!this.validateOnDemand) return
    const userInput = this.grabUserInput()
    const validateNewInput = this.validateData(userInput)
    this.setState(
      Object.assign(userInput, validateNewInput, this.validationErrors(validateNewInput))
    )
  }

  isValidated() {
    const userInput = this.grabUserInput()
    const validateNewInput = this.validateData(userInput)
    const { getStore, updateStore } = this.props
    return new Promise((resolve, reject) => {
      if (Object.keys(validateNewInput).every(k => validateNewInput[k] === true)) {
        if (
          getStore().userEmail !== userInput.userEmail ||
          getStore().personName !== userInput.personName ||
          getStore().userName !== userInput.userName ||
          getStore().personCity !== userInput.personCity
        ) {
          updateStore({
            ...userInput,
            savedToCloud: false,
          })
        }
        this.isExistingUser().then(isExisting => {
          resolve(!isExisting)
        })
      } else {
        this.setState(
          Object.assign(userInput, validateNewInput, this.validationErrors(validateNewInput))
        )
        resolve(false)
      }
    })
  }

  grabUserInput() {
    const { getStore } = this.props
    const { personCity } = this.state
    return {
      personCity,
      personName: this.personName.value,
      userName: this.userName ? this.userName.value : getStore().userName,
      userEmail: this.userEmail ? this.userEmail.value : getStore().userEmail,
    }
  }

  render() {
    const {
      companyLogo,
      companyName,
      hasSubmittedOnce,
      isCompany,
      personCityVal,
      personCityValMsg,
      personName,
      personNameVal,
      personNameValMsg,
      userEmail,
      userEmailVal,
      userEmailValMsg,
      userName,
      userNameVal,
      userNameValMsg,
    } = this.state
    const { updateStore } = this.props
    const shouldShowPersonCityError = hasSubmittedOnce && personCityVal === false
    const shouldShowPersonNameError = hasSubmittedOnce && personNameVal === false
    const shouldShowUserEmailError = hasSubmittedOnce && userEmailVal === false
    const shouldShowUserNameError = hasSubmittedOnce && userNameVal === false
    return (
      <section className="box tc ph2 w-100 pa3 pa4-ns mb3">
        {isCompany && <img alt={companyName} src={companyLogo} className="w4 mv2" />}
        <div className="mw8-ns center mt3-ns">
          <div className="">
            <form>
              <div className="flex flex-wrap w-50-ns center">
                <h2 className="ma0 mb3 mb4-ns fw5 f4 tc w-100 lh-title ph3 f5 f4-ns">
                  Informe os dados da pessoa homenageada
                </h2>
                <div className="mb3 w-100">
                  <div className={`input ${shouldShowPersonNameError ? 'has-error' : ''}`}>
                    <input
                      ref={c => {
                        this.personName = c
                      }}
                      id="personName"
                      autoComplete="off"
                      value={personName}
                      onChange={e => {
                        this.setState({
                          personName: toTitleCase(e.target.value),
                        })
                      }}
                      type="email"
                      className={`${personName !== '' ? 'filled' : ''}`}
                      onBlur={this.validationCheck}
                    />
                    <label htmlFor="personName">Nome da Pessoa Homenageada</label>
                    {shouldShowPersonNameError && <FormInlineError error={personNameValMsg} />}
                  </div>
                </div>
                <div className="mb3 w-100">
                  <City
                    onSelectCity={this.handleCity}
                    shouldShowCityError={shouldShowPersonCityError}
                    errorMessage={personCityValMsg}
                  />
                </div>
                <AuthUserContext.Consumer>
                  {authUser => {
                    if (authUser) {
                      updateStore({
                        userEmail: authUser.email,
                        userName: authUser.displayName,
                      })
                    }
                    return authUser ? (
                      <div>
                        {authUser.roles.includes('admin-church') && (
                          <div className="mb3 w-100">
                            {/* TODO: Add Death Date
                             <div className="dropdown">
                              <select
                                id="company"
                                className="filled pr4"
                                onChange={e => {
                                  this.setState({
                                    company: e.target.options[e.target.selectedIndex].value,
                                  })
                                }}
                              >
                                <option />
                                {range(
                                  personBirthday.getFullYear(),
                                  personDeathday.getFullYear()
                                ).map(item => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                              <label htmlFor="eventYear">Ano</label>
                            </div> */}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <h2 className="ma0 mb0 fw5 f4 tc w-100 lh-title ph3 f5 f4-ns">
                          Seus Dados
                        </h2>
                        <p className="mt2 mb3 gray f6 f5-ns tc w-100 lh-title">
                          Digite seu nome e email para enviarmos informações sobre o tributo criado
                        </p>
                        <div className="mb3 w-100">
                          <div className={`input ${shouldShowUserNameError ? 'has-error' : ''}`}>
                            <input
                              ref={c => {
                                this.userName = c
                              }}
                              id="userName"
                              defaultValue={userName}
                              type="text"
                              className={`${userName !== '' ? 'filled' : ''}`}
                              onBlur={this.validationCheck}
                            />
                            <label htmlFor="userName">Seu Nome</label>
                            {shouldShowUserNameError && <FormInlineError error={userNameValMsg} />}
                          </div>
                        </div>
                        <div className="mb3 w-100">
                          <div className={`input ${shouldShowUserEmailError ? 'has-error' : ''}`}>
                            <input
                              ref={c => {
                                this.userEmail = c
                              }}
                              id="userEmail"
                              autoComplete="off"
                              value={userEmail}
                              type="email"
                              className={`ttl ${userEmail !== '' ? 'filled' : ''}`}
                              onChange={event => {
                                this.setState({ [event.target.id]: event.target.value })
                                updateStore({ [event.target.id]: event.target.value })
                              }}
                              onKeyDown={e => {
                                if (e.keyCode === 13) {
                                  this.goToNextStep(e)
                                }
                              }}
                              onBlur={this.validationCheck}
                            />
                            <label htmlFor="userEmail">Seu Email</label>
                            {shouldShowUserEmailError && (
                              <FormInlineError error={userEmailValMsg} />
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  }}
                </AuthUserContext.Consumer>
                <div className="w-100 tr">
                  <button
                    type="button"
                    onClick={e => this.goToNextStep(e)}
                    className="btn btn-primary"
                  >
                    Próximo Passo
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    )
  }
}

export default withFirebase(Step1)
